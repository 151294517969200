import { default as _91_46_46_46slug_93nXsdeuoM6yMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/[...slug].vue?macro=true";
import { default as _404DZHhUMeT4OMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/404.vue?macro=true";
import { default as indexVhTnnKORejMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/arbochecklist/index.vue?macro=true";
import { default as bedanktLlhg8Ys5mzMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/bedankt.vue?macro=true";
import { default as _91slug_93qt77AvR7NtMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/contact/[slug].vue?macro=true";
import { default as indexXrt5OiKOZ9Meta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/contact/index.vue?macro=true";
import { default as indexSOpP6Zy4Y3Meta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93ekSgngc4zrMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/nieuws/[slug].vue?macro=true";
import { default as indexbWCdMYwnvBMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/nieuws/index.vue?macro=true";
import { default as open_45sollicitatie1Sqt1kFtxgMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/open-sollicitatie.vue?macro=true";
import { default as _91_46_46_46slug_93ZASTrlbOd8Meta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/over-westerduin/[...slug].vue?macro=true";
import { default as _91slug_93MTlUh4wXIoMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/referenties/[slug].vue?macro=true";
import { default as indexvSgT3F9NwtMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/referenties/index.vue?macro=true";
import { default as vacatures_45als_45_91slug_937lSJmQyL5jMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures-als-[slug].vue?macro=true";
import { default as vacatures_45bij_45_91slug_93mYYUVZCHSyMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures-bij-[slug].vue?macro=true";
import { default as vacatures_45in_45_91slug_93oNJvotQRqwMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures-in-[slug].vue?macro=true";
import { default as _91slug_935ewlOcKEHwMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures/[slug].vue?macro=true";
import { default as indexqZXOryubHsMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures/index.vue?macro=true";
import { default as vragenpP1eGBROULMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vragen.vue?macro=true";
import { default as _91_46_46_46slug_93ypd4WPGv9yMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/[...slug].vue?macro=true";
import { default as bedanktMz5PkuYxlMMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/bedankt.vue?macro=true";
import { default as faqjUSuUDYLIoMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/faq.vue?macro=true";
import { default as registratie_45inlenersbeloningYK7nVZOl8dMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/registratie-inlenersbeloning.vue?macro=true";
import { default as werkwijzeBnNy3f3j0sMeta } from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkwijze.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93nXsdeuoM6yMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "arbochecklist",
    path: "/arbochecklist",
    meta: indexVhTnnKORejMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/arbochecklist/index.vue").then(m => m.default || m)
  },
  {
    name: "bedankt",
    path: "/bedankt",
    meta: bedanktLlhg8Ys5mzMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/bedankt.vue").then(m => m.default || m)
  },
  {
    name: "contact-slug",
    path: "/contact/:slug()",
    meta: _91slug_93qt77AvR7NtMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/contact/[slug].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexXrt5OiKOZ9Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexSOpP6Zy4Y3Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nieuws-slug",
    path: "/nieuws/:slug()",
    meta: _91slug_93ekSgngc4zrMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/nieuws/[slug].vue").then(m => m.default || m)
  },
  {
    name: "nieuws",
    path: "/nieuws",
    meta: indexbWCdMYwnvBMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/nieuws/index.vue").then(m => m.default || m)
  },
  {
    name: "open-sollicitatie",
    path: "/open-sollicitatie",
    meta: open_45sollicitatie1Sqt1kFtxgMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/open-sollicitatie.vue").then(m => m.default || m)
  },
  {
    name: "over-westerduin-slug",
    path: "/over-westerduin/:slug(.*)*",
    meta: _91_46_46_46slug_93ZASTrlbOd8Meta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/over-westerduin/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "referenties-slug",
    path: "/referenties/:slug()",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/referenties/[slug].vue").then(m => m.default || m)
  },
  {
    name: "referenties",
    path: "/referenties",
    meta: indexvSgT3F9NwtMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/referenties/index.vue").then(m => m.default || m)
  },
  {
    name: "vacatures-als-slug",
    path: "/vacatures-als-:slug()",
    meta: vacatures_45als_45_91slug_937lSJmQyL5jMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures-als-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-bij-slug",
    path: "/vacatures-bij-:slug()",
    meta: vacatures_45bij_45_91slug_93mYYUVZCHSyMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures-bij-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-in-slug",
    path: "/vacatures-in-:slug()",
    meta: vacatures_45in_45_91slug_93oNJvotQRqwMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures-in-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures-slug",
    path: "/vacatures/:slug()",
    meta: _91slug_935ewlOcKEHwMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacatures",
    path: "/vacatures",
    meta: indexqZXOryubHsMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vacatures/index.vue").then(m => m.default || m)
  },
  {
    name: "vragen",
    path: "/vragen",
    meta: vragenpP1eGBROULMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/vragen.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-slug",
    path: "/werkgevers/:slug(.*)*",
    meta: _91_46_46_46slug_93ypd4WPGv9yMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-bedankt",
    path: "/werkgevers/bedankt",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/bedankt.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-faq",
    path: "/werkgevers/faq",
    meta: faqjUSuUDYLIoMeta || {},
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/faq.vue").then(m => m.default || m)
  },
  {
    name: "werkgevers-registratie-inlenersbeloning",
    path: "/werkgevers/registratie-inlenersbeloning",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkgevers/registratie-inlenersbeloning.vue").then(m => m.default || m)
  },
  {
    name: "werkwijze",
    path: "/werkwijze",
    component: () => import("/data/www/westerduin.nl/westerduin/releases/9/nuxt/pages/werkwijze.vue").then(m => m.default || m)
  }
]