import revive_payload_client_VmUwvOEZL8 from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_J8bC5LCz0h from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0lbOflyWON from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_4uIlr0yhQj from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.21.0_vite@5.4.1_@types+node@22.4.1_terser@5.3_c4kw4wcktgsgjok3wpxqp5dyze/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_khfZ1ROjZH from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zBlTgVfpgf from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hyDyzI6tNi from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FyqGOfbVnh from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_QEgkaA3ijL from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.4.1_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_ppr7sl7kftakzjuf5aplbb2dfm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_m7HNmTOaAl from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.21.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_7GnKwPNznV from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_fYrBfvsjGz from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.21.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_NYWz9Zeoke from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.8.2__rollup@4.21.0_typescrip_wokk4qzu5ahwaophvakgagek5m/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_client_3GcNROTbWT from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/nuxt-cookie-first@0.1.7_magicast@0.3.4_rollup@4.21.0/node_modules/nuxt-cookie-first/dist/runtime/plugin.client.mjs";
import errors_xiWjzqRIza from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.3_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_twienvrejjueyxth3y7tiyjytq/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_2CpwFGmot4 from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.3_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_twienvrejjueyxth3y7tiyjytq/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_gLBlTP38cd from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_eqkcnc55joq54ffumsfadii7ji/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_N9aMDcYGlJ from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_eqkcnc55joq54ffumsfadii7ji/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import body_scroll_lock_hNSArcroyK from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/plugins/body-scroll-lock.ts";
import dynamic_vw_client_kS7b5RjaKG from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/plugins/dynamic-vw.client.ts";
import vue_gtag_client_CYt6toK02h from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/plugins/vue-gtag.client.ts";
import vwo_client_iSRfeVlfgm from "/data/www/westerduin.nl/westerduin/releases/9/nuxt/plugins/vwo.client.ts";
export default [
  revive_payload_client_VmUwvOEZL8,
  unhead_J8bC5LCz0h,
  router_0lbOflyWON,
  _0_siteConfig_4uIlr0yhQj,
  payload_client_khfZ1ROjZH,
  navigation_repaint_client_zBlTgVfpgf,
  check_outdated_build_client_hyDyzI6tNi,
  chunk_reload_client_FyqGOfbVnh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QEgkaA3ijL,
  plugin_m7HNmTOaAl,
  switch_locale_path_ssr_7GnKwPNznV,
  i18n_fYrBfvsjGz,
  formkitPlugin_pZqjah0RUG,
  plugin_NYWz9Zeoke,
  plugin_client_3GcNROTbWT,
  errors_xiWjzqRIza,
  i18n_2CpwFGmot4,
  apollo_gLBlTP38cd,
  sofie_required_version_N9aMDcYGlJ,
  body_scroll_lock_hNSArcroyK,
  dynamic_vw_client_kS7b5RjaKG,
  vue_gtag_client_CYt6toK02h,
  vwo_client_iSRfeVlfgm
]